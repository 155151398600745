<template>
  <div class="msrcalendar"><h2 v-if="title">{{ title }}</h2>
  <table class="msrcalendar"><thead><tr>
    <th :colspan="totalColumns"><span style="float:left;"></span><span style="float:right;">To see thousands more, <a href="http://www.motorsportreg.com/calendar/" target="_blank">view the full calendar</a></span></th></tr>
    <tr>
      <th v-if="columns.includes('eventdate')">{{ $t('EVENT_DATE') }}</th>
      <th v-if="columns.includes('eventname')">{{ $t('EVENT_NAME') }}</th>
      <th v-if="columns.includes('organization')">{{ $t('ORGANIZATION') }}</th>
      <th v-if="columns.includes('venue')">{{ $t('EVENT_VENUE') }}</th>
      <th v-if="columns.includes('location')">{{ $t('VENUE_CITY') }}</th>
      <th v-if="columns.includes('type')">{{ $t('EVENT_TYPE') }}</th>
      <th>&nbsp;</th>
      </tr></thead><tbody>
        <tr v-if="events.loading"><td class="loading" :colspan="totalColumns">{{ $t('LOADING') }}</td></tr>
        <tr v-else-if="events.error"><td class="error" :colspan="totalColumns">{{ events.error }}</td></tr>
        <tr v-else-if="!events.data.length"><td :colspan="totalColumns">{{ $t('NO_UPCOMING_EVENTS') }}</td></tr>
        <tr v-else v-for="event in events.data" :key="event.id">
          <td v-if="columns.includes('eventdate')">{{ formatDate(event.start, event.end) }}</td>
      <td v-if="columns.includes('eventname')"><a :href="event.detailuri" class="txtlink">{{ event.name }}</a></td>
      <td v-if="columns.includes('organization')">{{  event.organization.name }}</td>
      <td v-if="columns.includes('venue')">{{ event.venue.name }}</td>
      <td v-if="columns.includes('location')">{{ event.venue.city }}, {{ event.venue.region }}</td>
      <td v-if="columns.includes('type')">{{ event.type }}</td>
      <td><a :href="event.detailuri" class="txtlink">{{ $t('DETAILS') }}</a></td>
        </tr>
      </tbody></table><div class="morelink">MotorsportReg.com provides online registration for thousands of <a href="http://www.motorsportreg.com/calendar/">track, HPDE, autocross, club racing and karting events</a>.</div></div>
</template>

<script>
// Might need:
// <sc ript src="https://cdnjs.cloudflare.com/ajax/libs/webcomponentsjs/1.2.0/webcomponents-loader.js"></scr ipt>
// https://github.com/WebComponents/webcomponentsjs
// const MOCKS = require('@/mocks')
const AVAILABLE_COLUMNS = ['eventdate','eventname','organization','venue','location','type']
const TRANSLATIONS = {
  EVENT_DATE: 'Event Date',
  EVENT_NAME: 'Event Name',
  EVENT_TYPE: 'Event Type',
  EVENT_VENUE: 'Event Venue',
  VENUE_CITY: 'Venue City',
  ORGANIZATION:'Organization',
  NO_UPCOMING_EVENTS: 'No upcoming events for this organization.',
  LOADING: 'Loading...',
  DETAILS: 'Details',
}
export default {
  name: 'MsrCalendar',
  props: {
    organizationId: { //8B9C558F-EC06-5317-566C9704F3F5F8A1
      type: String,
      default: null, // So we can handle errors ourself
    },
    title: {
      type: String,
      default: null
    },
    hide: {
      type:String,
      default: () => '' // Comma-separated list of columns to hide
    }
  },
  created () {
    const organizationIds = this.organizationId.split(',').map(id => id.trim())
    this.fetchAllOrganizations(organizationIds)
  },
  data: function () {
    return {
      events: {
        loading: true,
        error: null,
        data: null, // MOCKS.response.events,
      }
    }
  },
  computed: {
    totalColumns () {
      return this.columns.length + 1
    },
    columns () {
      const hide = this.hide.split(',').map(s => s.toLowerCase().trim())
      return AVAILABLE_COLUMNS.filter(c => !hide.includes(c))
    },
    sortedEvents () {
      return this.events.data
    }
  },
  methods: {
    apiUrl (organizationId) {
      return `https://api.motorsportreg.com/rest/calendars/organization/${organizationId}.json`
    },
    $t (key) {
      return Object.getOwnPropertyDescriptor(TRANSLATIONS, key) ? TRANSLATIONS[key] : key
    },
    formatDate (start, end) {
  //     	$start=strtotime($start_date);
	// $start_year=date('y',$start);
	// $start_month=date('M',$start);
	// $start_day=date('j',$start);
	
	// if($end_date){
	// 	$end=strtotime($end_date);
	// 	$end_year=date('y',$end);
	// 	$end_month=date('M',$end);
	// 	$end_day=date('j',$end);
	// }
	
	// if($end_date)
	// {
	// 	if($start_month == $end_month)
	// 	{
	// 		if($start_day == $end_day)
	// 			$date_display=$start_month.' '.$start_day;
	// 		else
	// 			$date_display= $start_month.' '.$start_day.'-'.$end_day;
			
	// 	}else{
	// 		$date_display= $start_month.' '.$start_day.' - '.$end_month.' '.$end_day;
	// 	}
	// }else{
	// 	$date_display=$start_month.' '.$start_day;
	// }

  // return $date_display;
      const locale = 'en-US'
      const options = { month: 'short',  day: 'numeric' } // { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
      start = new Date(start).toLocaleDateString(locale, options)
      end = new Date(end).toLocaleDateString(locale, options)
      return start == end ? start : `${start} - ${end}`
    },
    async fetchAllOrganizations(organizationIds) {
      try {
        this.events.loading = true
        this.events.error = null
        let events = await Promise.all(organizationIds.map(id => this.fetchEvents(id)))
        events = events.reduce((acc, events) => {
          return [...acc, ...events]
        }, [])
        events.sort((a, b) => new Date(a.start) - new Date(b.start))
        this.events.data = events
      } catch (e) {
        this.events.error = e.message
      } finally {
        this.events.loading = false
      }
    },
    fetchEvents (organizationId) {
      // @todo -- error handing if no org id.
      // @todo -- error handling on bad org id/MSR api error
      const apiUrl = this.apiUrl(organizationId)
      return fetch(apiUrl)
        .then(response => {
          return response.json()
        })
        .then(result => {
          if (result.error) {
            // eslint-disable-next-line
            console.error(result)

            if (result.error === 'Invalid ID') {
              throw new Error(`Error from MotorsportReg API for ${organizationId}: ${result.error}. Please check the "organization-id" parameter provided to the calendar widget.`)
            } else {
              throw new Error(`Error from MotorsportReg API for ${organizationId}: ${result.error}`)
            }
          }
          return result.response.events
        })
    }
  }
}
</script>

<style scoped>
.msrcalendar h2 { font: 100% verdana, arial, sans-serif;
color:#444;
font-size: 125%; font-weight: bold; margin-bottom: 5px; padding: 10px 0 0 10px; color: #369AC9; }

.msrcalendar table { 
margin:0; 
border-collapse:collapse; 
width: 100%; 
font: 100% verdana, arial, sans-serif;
color:#444;
}
.msrcalendar table thead td { padding-bottom: 10px; }
.msrcalendar table thead th {
font-size:14px!important;
font-weight:bold;
text-align: left;
padding:4px 0 4px 10px;
background:none;
border-style:solid;
border-color:#369AC9;
border-width:0 0 1px 0;
color:#369AC9;
white-space:nowrap;
} 

.msrcalendar table tbody td { 
padding:10px; 
border-style:solid; 
border-color:#ccc; 
border-width:0 0 1px 0; 
font-size:12px;
}
.msrcalendar .error{
  color: red;
}
.morelink a{
font-size:12px!important;
	
}

.msrcalendar table tbody td a.imglink { color: #8DD252; }
.msrcalendar table tbody td a.imglink:hover { background: none; }

.msrcalendar table tbody td a.txtlink { color: #369AC9; }

/* please do not remove the morelink; this is the only requirement for using the wordpress plugin is to leave an unedited link back to MSR.com, thanks! */
.msrcalendar .morelink { font: 100% verdana, arial, sans-serif;
color:#444;
font-size: 12px!important; font-weight: normal; padding-left: 10px; }
</style>
